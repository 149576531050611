body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapboxgl-popup-content {
  border-radius: 12px !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 0 !important;
}

.mapboxgl-popup-close-button{
  background-color: white !important;
  font-size:"1rem";
  border-bottom-left-radius: 12px !important;
}

.custom-scrollbar {
  scrollbar-width: none; /* Hide the default scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide the default scrollbar in IE/Edge */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide the default scrollbar in Chrome, Safari, and Opera */
}

/* Remove blue border for maps */
.gm-style iframe + div { border:none!important; }


/* Typing animation */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Google Maps Zoom Control */
.gmnoprint > div {
  border-radius: 12px !important;
}

/* Google Maps Street View Control */
.gm-svpc {
  border-radius: 12px !important;
}
